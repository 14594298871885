import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  API_url= env.API_URL;
 
  headers:any;
  constructor(private httpClient: HttpClient) { }

  getUserInfo(){
    

    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Authorization', 'Bearer '+sessionStorage.getItem('token'));

    
    return this.httpClient.get(this.API_url+'userinfo',{
      headers: this.headers
    });
  }
}
