<!-- <div class="toolbar" role="banner">
    <img src="assets/img/wabo-logo-2.png" />
    <div class="spacer"></div>
    <span style="float: right;">Cerrar Sesion</span>
</div>
  <div class="container">
    <mat-sidenav-container class="example-container" autosize>
      <mat-sidenav class="sidenav" mode="side" opened>
        <mat-selection-list style="margin-top: 40px;" [multiple]="false">
          <mat-list-option>Árbol de deciciones</mat-list-option>
          <mat-list-option>Documentación</mat-list-option>
          <mat-list-option>Soporte</mat-list-option>
          <mat-list-option>Facturación</mat-list-option>
        </mat-selection-list>
      </mat-sidenav>
      <mat-sidenav-content>
      </mat-sidenav-content>
    </mat-sidenav-container>
</div> -->
<router-outlet></router-outlet>
