import { Component, OnInit } from '@angular/core';
import {  ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';



@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  params: any;
  constructor(public auth: AuthService,private rourting:Router,private actRoute: ActivatedRoute) {}

  ngOnInit() {
    
    console.log('*: ', this.actRoute.snapshot.params);
    if (this.actRoute.snapshot.fragment !== null) {
      let aux = this.actRoute.snapshot.fragment;
      let aux2 = aux.split("&", 2);
      this.params = aux2[0].split('=', 2);

      sessionStorage.setItem("token", this.params[1]);
    }
    setTimeout(() => {
      this.rourting.navigateByUrl('home');
    }, 2000);

  }
}
